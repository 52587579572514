
import { defineComponent } from 'vue';
import Accordian from '@/components/clickables/Accordian.vue';
import PdfViewer from '@/components/pdf/PdfViewer.vue';
import ContentEditor from '@/views/SmPC/editor/ContentEditor.vue';
import UiOverlay from '@/components/form/UiOverlay.vue';
import UiButton from '@/components/form/UiButton.vue';
import UiTextarea from '@/components/form/UiTextarea.vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import SmPCService from '@/services/SmPCService';
import {
  SmpcDetails,
  SmpcDetailsUpdate,
  SmpcStatus,
  SmpcSection,
  SmpcConfirmModalConf,
} from '@/models/smpc';
import { ApiError } from '@/models/auth';
import UiErrors from '@/components/form/UiErrors.vue';

export default defineComponent({
  name: 'EditSmPC',
  components: {
    Accordian,
    PdfViewer,
    ContentEditor,
    UiOverlay,
    UiButton,
    UiTextarea,
    PreLoader,
    UiErrors,
  },
  data: () => ({
    smpcDetails: {} as SmpcDetails,
    smpcStatus: '',
    pdfUrl: '' as string,
    isUpdating: false,
    isImageProcessing: false,
    onDeleteImageId: '',
    onDeleteImageExists: false,
    confirmationModal: false,
    confirmModal: {} as SmpcConfirmModalConf,
    isLoaded: false,
    filteredSmpcSections: [] as SmpcSection[],
    preview: true,
    pdfLoaded: false,
    editorStates: [] as boolean[],
    errors: {} as any,
  }),
  computed: {
    isEmpty(): boolean {
      return this.smpcDetails.images.length <= 0;
    },
    smpcTemplateVersion(): any {
      return this.smpcDetails?.artifact?.template_version || '';
    },
  },
  async created(): Promise<void> {
    const id = this.$route.params.id as string;
    const smpc = await SmPCService.showSmpc(id);
    this.smpcDetails = smpc;
    this.smpcStatus = smpc.status;

    const filtered: SmpcSection[] = this.smpcDetails.smpc_details.smpc_sections
      .map((section) => {
        const temp = section;

        if (!section.value.includes('</p>')) {
          temp.value = section.value.replace(/\n/g, '<br />');
        }

        return temp;
      });

    this.filteredSmpcSections = [...filtered];

    this.filteredSmpcSections.forEach(() => {
      this.editorStates.push(false);
    });

    const artifact = await SmPCService.getArtifact(smpc.artifact_id);

    const pdfUrl = await SmPCService.getMediaPdf(artifact.source);
    this.pdfUrl = pdfUrl;
    this.isLoaded = true;
  },
  methods: {
    async saveAndClose() {
      this.isUpdating = true;

      const response = await this.updateSmpc();

      const apierror: ApiError = response as ApiError;

      if (apierror?.message) {
        this.isUpdating = false;

        this.$toast.show(`Error: ${apierror.message}`, 3000);
      } else {
        this.$toast.show('SmPC saved succefully', 3000);
        this.$router.push({ name: 'ManageSmpcs' });
      }

      this.isUpdating = false;
    },
    isNotEmpty(obj: Record<string, unknown>): boolean {
      return Object.keys(obj).length !== 0;
    },
    createImageSource(imageId: string): string {
      return `${process.env.VUE_APP_BASE_API_URL}/api/smpc/media/show/${imageId}`;
    },
    removeSection(index: any) {
      this.filteredSmpcSections.splice(index, 1);
      delete this.errors[`title_${index}`];
      this.editorStates.splice(index, 1);
    },
    addNewSection() {
      const smpcSections: SmpcSection[] = this.filteredSmpcSections;
      let key = '';
      let nextKey = 0;
      try {
        key = smpcSections[smpcSections.length - 1].key;
        nextKey = Number(key.split('.')[0]) + 1;
      } catch (error) {
        key = '1.';
      }

      nextKey = Number(key.split('.')[0]) + 1;

      const newSection: SmpcSection = {
        key: `${nextKey}.`,
        sub_sections: [],
        title: 'New Section',
        value: 'New Section Value',
      };
      this.$toast.show('New Section added', 3000);
      this.filteredSmpcSections.push(newSection);
    },
    async updateSmpc() {
      this.errors = {};

      if (!this.smpcDetails.name) {
        this.errors.name = ['Title required'];
      }

      for (let i = 0; i < this.filteredSmpcSections.length; i += 1) {
        if (!this.filteredSmpcSections[i]?.title) {
          this.errors[`title_${i}`] = ['Title required'];
          this.editorStates[i] = true;
        } else {
          this.editorStates[i] = false;
        }
      }

      if (Object.keys(this.errors).length) {
        return { message: 'Please enter correct data' };
      }

      const details: SmpcDetailsUpdate = {
        name: this.smpcDetails.name,
        smpc_details: { smpc_sections: this.filteredSmpcSections },
        images: this.smpcDetails.images,
      };

      const response = await SmPCService.updateSmpc(this.$route.params.id as string, details);

      return response;
    },
    async saveSmpc() {
      this.isUpdating = true;

      const response = await this.updateSmpc();

      const apierror: ApiError = response as ApiError;

      if (apierror?.message) {
        this.isUpdating = false;

        this.$toast.show(`Error: ${apierror.message}`, 3000);
      } else {
        this.$toast.show('SmPC saved succefully', 3000);
      }

      this.isUpdating = false;
    },
    async approveSmpc() {
      this.isUpdating = true;

      const updateRes = await this.updateSmpc();

      const updateErr: ApiError = updateRes as ApiError;

      if (updateErr?.message) {
        this.isUpdating = false;

        this.$toast.show(`Error: ${updateErr.message}`, 3000);
        return;
      }

      const approveRes = await SmPCService.approveSmpc(this.$route.params.id as string);

      const approveErr: ApiError = approveRes as ApiError;

      if (approveErr?.message) {
        this.isUpdating = false;

        this.$toast.show(`Error: ${approveErr.message}`, 3000);
        return;
      }

      this.smpcStatus = SmpcStatus.APPROVED;
      this.$toast.show('SmPC approved succefully', 3000);
      this.isUpdating = false;
    },
    async askUnapproveSmpc() {
      this.confirmModal = {
        show: true,
        title: 'WARNING!',
        description: 'Are you sure you want to unapprove this SmPC?',
      };
    },
    async unapproveSmpc() {
      this.isUpdating = true;
      const response = await SmPCService.unapproveSmpc(this.$route.params.id as string);

      const apierror: ApiError = response as ApiError;

      if (apierror?.message) {
        this.$toast.show(`Error: ${apierror.message}`, 3000);
      } else if (response === 1) {
        this.smpcStatus = SmpcStatus.PENDING;
        this.$toast.show('SmPC unapproved succefully', 3000);
      }

      this.confirmModal.show = false;

      this.isUpdating = false;
    },
    onError(index: number) {
      this.smpcDetails.images.splice(index, 1);
    },
    onCaptureBeginHandler(): void {
      this.$toast.show('Cropping Image', 3000);
      this.isImageProcessing = true;
    },
    async onCaptureHandler(file: File) {
      const image = await SmPCService.uploadImage(await file);
      this.smpcDetails.images.unshift(image.id);
      this.isImageProcessing = false;
      const pdfViewer = this.$refs.pdfViewer as any;
      pdfViewer.onClickCropper();
      this.$toast.show('Image Cropped', 3000);
    },
    onDeleteHandler(id: string): void {
      this.onDeleteImageId = id;

      this.onDeleteImageExists = false;

      const sections = this.filteredSmpcSections;

      for (let index = 0; index < sections.length; index += 1) {
        const element = document.createElement('p');
        const targetImageId = `#image-${id}`;
        element.innerHTML = sections[index].value;
        const images = element.querySelectorAll(targetImageId);
        if (images.length > 0) {
          this.onDeleteImageExists = true;
          break;
        }
      }

      this.confirmationModal = true;
    },
    async onDeleteConfirmed(): Promise<void> {
      this.confirmationModal = false;

      if (!this.onDeleteImageExists) {
        this.smpcDetails.images = this.smpcDetails.images.filter(
          (imageId) => imageId !== this.onDeleteImageId,
        );
      }

      this.onDeleteImageId = '';
    },
  },
});
