
import { defineComponent } from 'vue';
import Editor from '@tinymce/tinymce-vue';
import {
  ToolBarItems,
  EditorCommands,
} from '@/models/smpc';
import UiErrors from '@/components/form/UiErrors.vue';

export default defineComponent({
  name: 'ContentEditor',
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: 'Empty title...',
    },
    content: {
      type: String,
      required: true,
      default: 'Empty content...',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Editor,
    UiErrors,
  },
  emits: ['update:content', 'update:title', 'section:remove'],
  watch: {
    titleData() {
      this.$emit('update:title', this.titleData);
    },
    contentData() {
      this.$emit('update:content', this.contentData);
    },
  },
  data() {
    return {
      titleData: this.title,
      contentData: this.content,
      apiKey: process.env.VUE_APP_SMPC_CONTENT_EDITOR_API_KEY,
      loaded: false,
      isPrimaryControlActive: true,
      inputAction: {
        fontSize: 0,
        wordSpacing: 0,
        lineHeight: 0,
        paragraphSpacing: 0,
      },
      formatActions: [
        {
          command: EditorCommands.BOLD,
          condition: undefined,
          content: '<b>T<b>',
        },
        {
          command: EditorCommands.ITALIC,
          condition: undefined,
          content: '<i>T<i>',
        },
        {
          command: EditorCommands.UPPERCASE,
          condition: undefined,
          content: 'TT',
        },
        {
          command: EditorCommands.LOWERCASE,
          condition: undefined,
          content: 'tt',
        },
        {
          command: EditorCommands.TITLECASE,
          condition: undefined,
          content: 'Tt',
        },
        {
          command: EditorCommands.TOGGLEFORMAT,
          condition: 'superscript',
          content: 'T<sup>1<sup>',
        },
        {
          command: EditorCommands.TOGGLEFORMAT,
          condition: 'subscript',
          content: 'T<sub>1<sub>',
        },
        {
          command: EditorCommands.TOGGLEFORMAT,
          condition: 'underline',
          content: '<u>T<u>',
        },
        {
          command: EditorCommands.TOGGLEFORMAT,
          condition: 'strikethrough',
          content: '<strike>T<strike>',
        },
      ],
      letters: [
        '°',
        '©',
        '®',
        'μ',
        'α',
        'β',
        'γ',
        '±',
        '<',
        '>',
        '≤',
        '≥',
        '¾',
        '½',
      ],
      toolbar: Object.values(ToolBarItems),
      editorInstance: {} as any,
    };
  },
  methods: {
    setupEditorConfiguration(editor: any): void {
      this.editorInstance = editor;

      editor.ui.registry.addButton('wordspacing', {
        text: 'Word Space',
        onAction: () => {
          const source = editor.selection.getNode() as HTMLElement | null;
          if (source) {
            source.style.wordSpacing = `${this.inputAction.wordSpacing}px`;
          }
        },
      });

      editor.ui.registry.addButton('paragraphspacing', {
        text: 'Paragraph Space',
        onAction: () => {
          const source = editor.selection.getNode() as HTMLElement | null;
          if (source) {
            source.style.marginBottom = `${this.inputAction.paragraphSpacing}px`;
          }
        },
      });
    },
    onEditorLoadHandler(): void {
      this.loaded = true;

      const iframe = document.querySelector(`#${this.id}_ifr`) as HTMLIFrameElement;
      const iframeStyles = document.createElement('style');
      iframeStyles.textContent = 'p { margin: 0 }';
      if (iframe.contentDocument) {
        iframe.contentDocument.head.append(iframeStyles);
      }
    },
    onClickHandler(event: MouseEvent): void {
      const element = event?.target as HTMLElement;

      const style = window.getComputedStyle(element, null);

      this.inputAction.fontSize = 0.75 * Number(style.fontSize.split('px')[0]);
      this.inputAction.wordSpacing = 0.75 * Number(style.wordSpacing.split('px')[0]);
      this.inputAction.paragraphSpacing = 0.75 * Number(style.marginBottom.split('px')[0]);
      this.inputAction.lineHeight = ((0.75 * Number(style.lineHeight.split('px')[0])) / this.inputAction.fontSize) || 0;
    },
    onExecuteCommandHandler(command: string, condition?: string): void {
      switch (command) {
        case EditorCommands.FONTSIZE:
          this.editorInstance.execCommand(command, false, `${this.inputAction.fontSize}pt`);
          break;

        case EditorCommands.BOLD:
          this.editorInstance.execCommand(command);
          break;

        case EditorCommands.ITALIC:
          this.editorInstance.execCommand(command);
          break;

        case EditorCommands.LINEHEIGHT:
          this.editorInstance.execCommand(command, false, this.inputAction.lineHeight);
          break;

        case EditorCommands.TOGGLEFORMAT:
          this.editorInstance.execCommand('mceToggleFormat', false, condition);
          break;

        case EditorCommands.UPPERCASE:
          this.editorInstance.execCommand(command);
          break;

        case EditorCommands.LOWERCASE:
          this.editorInstance.execCommand(command);
          break;

        case EditorCommands.TITLECASE:
          this.editorInstance.execCommand(command);
          break;

        case EditorCommands.INSERTSYMBOL:
          this.editorInstance.execCommand(command, false, condition);
          break;

        default:
          break;
      }
    },
    onWordSpacing(): void {
      this.editorInstance.ui.registry.getAll().buttons.wordspacing.onAction();
    },
    onParagraphSpacing(): void {
      this.editorInstance.ui.registry.getAll().buttons.paragraphspacing.onAction();
    },
  },
});
