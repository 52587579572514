import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "label"
}
const _hoisted_2 = ["placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-textarea", { disabled: _ctx.disabled, 'read-only': _ctx.readOnly }]),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setFocus && _ctx.setFocus(...args)))
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("textarea", {
      ref: "ui-textarea",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled || _ctx.readOnly
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.model]
    ])
  ], 2))
}