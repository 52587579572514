
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import Vue3DraggableResizable from 'vue3-draggable-resizable';
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css';
import { defineComponent } from 'vue';
import {
  PDFDocumentLoadingTask,
  PDFDocumentProxy,
  PDFPageProxy,
  TextContent,
} from 'pdfjs-dist/types/display/api.d';
import { PageViewport } from 'pdfjs-dist/types/display/display_utils.d';
import html2canvas from 'html2canvas';
import * as WORKER_SOURCE from 'pdfjs-dist/build/pdf.worker.entry';

interface CanvasContext {
  canvasContext: CanvasRenderingContext2D;
  viewport: PageViewport;
}

export default defineComponent({
  name: 'Pdf Viewer',
  components: {
    Vue3DraggableResizable,
  },
  props: {
    pdfUrl: {
      type: String,
      required: true,
    },
  },
  emits: ['capture:image', 'capture:begin', 'pdf:loaded'],
  data() {
    return {
      totalPages: 0 as number,
      pageNumber: 1 as number,
      scale: 1.366,
      HIGHLIGHTED: false,
      searchQuery: '[0-9]',
      w: 0,
      h: 0,
      x: 10,
      y: 40,
      active: false,
      screenshot: false,
      isLoaded: false,
      textLayers: [] as HTMLDivElement[],
      canvaLayers: [] as HTMLCanvasElement[],
      isHighlighted: true,
    };
  },
  methods: {
    pdfInit(): void {
      this.isLoaded = false;
      this.HIGHLIGHTED = false;

      this.pdfClearPrevious();

      const loadingTask: PDFDocumentLoadingTask = pdfjsLib.getDocument(
        this.pdfUrl,
      );

      loadingTask.promise.then((pdf: PDFDocumentProxy) => this.pdfLoad(pdf));
    },

    pdfClearPrevious() {
      const pdfViewer = document.querySelector('#pdfViewer') as HTMLElement;

      if (pdfViewer) {
        try {
          while (pdfViewer.firstChild) {
            const child = pdfViewer.lastChild;
            if (child) {
              pdfViewer.removeChild(child);
            }
          }
        } catch (error) {
          return;
        }
      }

      if (this.canvaLayers.length > 0) {
        this.canvaLayers = [];
      }

      if (this.textLayers.length > 0) {
        this.textLayers = [];
      }
    },

    async pdfLoad(pdf: PDFDocumentProxy): Promise<void> {
      this.totalPages = pdf.numPages;

      const promises: Promise<PDFPageProxy>[] = [];

      for (let index = 1; index < pdf.numPages + 1; index += 1) {
        promises.push(pdf.getPage(index).then(async (page: PDFPageProxy) => {
          await this.pdfBuildPage(page);
        }) as Promise<PDFPageProxy>);
      }

      await Promise.all<PDFPageProxy>(promises);

      this.pdfObserver();

      this.isLoaded = true;

      this.$emit('pdf:loaded');
    },

    async pdfBuildPage(page: PDFPageProxy): Promise<void> {
      const viewport: PageViewport = page.getViewport({ scale: this.scale });

      const canvas: HTMLCanvasElement = document.createElement('canvas');

      canvas.id = page.pageNumber.toString();

      const context: CanvasRenderingContext2D = canvas.getContext(
        '2d',
      ) as CanvasRenderingContext2D;

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext: CanvasContext = {
        canvasContext: context,
        viewport,
      };

      const renderTask = page.render(renderContext);

      await renderTask.promise
        .then()
        .then(() => page.getTextContent())
        .then((textContent: TextContent | undefined) => {
          this.canvaLayers.push(canvas);
          this.pdfBuildTextLayer(canvas, viewport, textContent);
        });
    },

    pdfObserver() {
      const pdfViewer = document.querySelector('#pdfViewer');

      this.canvaLayers.sort((a, b) => Number(a.id) - Number(b.id));

      this.textLayers.sort((a, b) => Number(a.id.split('-')[1]) - Number(b.id.split('-')[1]));

      this.canvaLayers.forEach((canva: HTMLCanvasElement, canvaIndex) => {
        if (pdfViewer) {
          const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting === true) {
              const element = entries[0] as IntersectionObserverEntry;

              this.pdfCheckTextLayer(Number(element.target.id) - 1);

              this.textLayers[Number(element.target.id) - 1].style.top = `${canva.offsetTop}px`;

              pdfViewer.insertAdjacentElement('afterbegin', this.textLayers[Number(element.target.id) - 1]);

              this.pdfSearchQuery();
            }
          }, { threshold: [0] });

          observer.observe(canva);
          pdfViewer.insertAdjacentElement('beforeend', canva);

          if (canvaIndex < 2) {
            this.pdfCheckTextLayer(canvaIndex);

            this.textLayers[canvaIndex].style.top = `${canva.offsetTop}px`;

            pdfViewer.insertAdjacentElement('afterbegin', this.textLayers[canvaIndex]);

            this.pdfSearchQuery();
          }
        }
      });
    },

    pdfCheckTextLayer(range: number): void {
      const MIN = range - 1 < 0 ? 0 : range - 1;
      const MAX = range + 1 > this.textLayers.length ? this.textLayers.length : range + 1;

      for (let index = 0; index < this.textLayers.length; index += 1) {
        if (!(index >= MIN && index <= MAX)) {
          const layer = document.querySelector(`#${this.textLayers[index].id}`);

          if (layer) {
            layer.remove();
          }
        }
      }
    },

    async pdfBuildTextLayer(
      canvas: HTMLCanvasElement,
      viewport: PageViewport,
      textContent: TextContent | undefined,
    ): Promise<void> {
      const textLayer: HTMLDivElement = document.createElement('div');

      textLayer.classList.add('textLayer');

      textLayer.id = `textLayer-${canvas.id}`;

      textLayer.style.height = `${canvas.height}px`;
      textLayer.style.width = `${canvas.width}px`;

      await pdfjsLib.renderTextLayer({
        textContent,
        container: textLayer,
        viewport,
        textDivs: [],
      });

      this.textLayers.push(textLayer);
    },

    pdfSearchQuery(): void {
      const elements = document.querySelectorAll('.textLayer');
      elements.forEach((element: Element) => {
        const childrens: Element[] = Array.from(element.children);

        childrens.forEach((child: Element) => {
          const target: Element = child;

          if (target.tagName === 'SPAN') {
            target.innerHTML = this.pdfHighlighter(target.innerHTML, this.HIGHLIGHTED);
          }
        });

        this.HIGHLIGHTED = this.isHighlighted;
      });
    },

    pdfHighlighter(string: string, highlight: boolean): string {
      if (highlight) {
        const regex = new RegExp(`${this.searchQuery}`, 'g');
        return string.replace(regex, (replacer: string) => `<font class='highlight-text'>${replacer}</font>`);
      }

      const regex = new RegExp('<font class="highlight-text">|</font>', 'g');
      return string.replace(regex, '');
    },

    pdfCapture(): void {
      this.$emit('capture:begin');
      const viewer = document.querySelector('#pdfViewer') as HTMLElement;
      html2canvas(viewer, { scale: 1 }).then(
        async (canvas: HTMLCanvasElement) => {
          const croppedCanvas = document.createElement('canvas');

          const croppedCanvasContext = croppedCanvas.getContext(
            '2d',
          ) as CanvasRenderingContext2D;

          croppedCanvas.width = this.w;
          croppedCanvas.height = this.h;

          croppedCanvasContext.drawImage(
            canvas,
            this.x,
            this.y,
            this.w,
            this.h,
            0,
            0,
            this.w,
            this.h,
          );

          const output = await this.base64ToBlob(croppedCanvas.toDataURL());

          this.$emit('capture:image', output);
        },
      );
    },

    async base64ToBlob(url: string): Promise<File> {
      const response = await fetch(url);
      const blob: Blob = await response.blob();
      const file = new File([blob], 'File.png', { type: 'image/png' });
      return file;
    },

    onClickCropper(): void {
      this.screenshot = !this.screenshot;
      this.active = !this.active;
      this.isHighlighted = !this.isHighlighted;
    },

    scaleUp(): void {
      if (this.scale !== 4) {
        this.scale += 0.1;
      }
    },

    scaleDown(): void {
      if (this.scale !== 0.1) {
        this.scale -= 0.1;
      }
    },
  },
  created() {
    pdfjsLib.GlobalWorkerOptions.workerSrc = WORKER_SOURCE;
  },
  watch: {
    pageNumber() {
      this.pdfInit();
    },
    scale() {
      this.pdfInit();
    },
    pdfUrl() {
      if (this.pdfUrl) {
        this.pdfInit();
      }
    },
    isHighlighted(val: boolean) {
      this.HIGHLIGHTED = val;
      this.pdfSearchQuery();
    },
  },
});
