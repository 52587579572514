export enum SmpcStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING'
}

export enum EditorCommands {
  FONTSIZE = 'FontSize',
  BOLD = 'Bold',
  ITALIC = 'Italic',
  LINEHEIGHT = 'LineHeight',
  TOGGLEFORMAT = 'mceToggleFormat',
  UPPERCASE = 'mceUpperCase',
  LOWERCASE = 'mceLowerCase',
  TITLECASE = 'mceTitleCase',
  INSERTSYMBOL = 'mceInsertContent',
  LINK = 'mceLink',
}

export enum ToolBarItems {
  FONTSIZESELECT = 'fontsizeselect',
  FORMATSELECT = 'formatselect',
  WORDSPACING = 'wordspacing',
  PARAGRAPHSPACING = 'paragraphspacing',
  LINEHEIGHT = 'lineheight',
  CASECHANGE = 'casechange',
  SUPERSCRIPT = 'superscript',
  SUBSCRIPT = 'subscript',
  UNDERLINE = 'underline',
  STRIKETHROUGH = 'strikethrough',
  DEGREE = 'Degree',
  COPYRIGHT = 'Copyright',
  REGISTERED = 'Registered',
  MICRO = 'Micro',
  ALPHA = 'Alpha',
  BEETA = 'Beta',
  GAMMA = 'Gamma',
  PLUSMINUS = 'Plusminus',
  LESSTHAN = 'Lessthan',
  GREATERTHAN = 'Greaterthan',
  LESSTHANEQUAL = 'Lessthanequal',
  GREATERTHANEQUAL = 'Greaterthanequal',
  THREEQUARTER = 'Threequarter',
  HALF = 'Half',
}

export interface Smpc {
  smpc_id: string;
  spmc_name: string;
  artifact_id: string;
  artifact_name: string;
  smpc_status: SmpcStatus;
  smpc_created_at: string;
}

export interface SmpcResponse {
  data: Smpc[];
  total: number;
  per_page: number;
  current_page: number;
}

export interface SmpcSection {
  key: string;
  title: string;
  value: string;
  sub_sections: SmpcSection[] | null;
}

export interface SmpcArtifact {
  id: string;
  template_version: string;
}

export interface SmpcDetails {
  id: string;
  name: string;
  artifact: SmpcArtifact,
  artifact_id: string;
  smpc_details: {
    smpc_sections: SmpcSection[];
  };
  draft_images: string[];
  images: string[];
  status: SmpcStatus;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface SmpcDetailsUpdate {
  name: string,
  smpc_details: {
    smpc_sections: SmpcSection[];
  };
  images: string[];
}

export interface SmpcConfirmModalConf {
  show: boolean;
  title: string;
  description: string;
  // TODO: Confirm action with callback function
  // TODO: Cancel action with callback function
}

export interface Artifact {
  id: string;
  name: string;
  key: string;
  source: string;
  status: SmpcStatus;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface SmpcUploadFileResponse {
  created_at: string
  id: string
  library_id: string
  size: number
  source: string
  title: string
  type: string
  updated_at: string
  version: any,
}

export interface ProcessedArtifact {
  id: string;
  name: string;
}

export interface UploadSuccess {
  name: string;
  size: number;
  type: string;
}

export interface UploadFailed extends UploadSuccess {
  error: string;
}
